:root {
    --red: #FF0043;
    --white: #ffffff;
    --verde: #07A781;
    --morado: #7643F6;
    --black: #000000;
    --gris: #858484;
    --yelow: #d9e806;
    --azul: #01A0E2;
    --rosado: #EC1074;
    --naraja: #F9970E;
}

.header {
    background: linear-gradient(to right, var(--red), var(--black));
    position: fixed;
    top: 0;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    height: 50px;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    z-index: 1030;
}

.headerImg {
    height: 30px;
}

.imgCarousel {
    width: 100%;
    height: auto;
}

.carousel-container {
    position: relative;
}

.custom-dot {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #aeaeae;
    border-radius: 50%;
    cursor: pointer;
}

.slick-active .custom-dot {
    background: white;
    width: 12px;
    height: 12px;
}

.slick-dots {
    bottom: 10px !important;
    justify-content: center;
    display: flex !important;
}

.slick-dots li {
    display: flex !important;
    align-items: center;
}

.slick-arrow {
    display: none !important;
}

.section-title {
    margin-bottom: 2rem;
}

.colorRed {
    color: var(--red);
}

.card-event__title {
    font-size: 14px !important;
    font-weight: 600 !important;
    transition: 0.4s all;
    line-height: 1.4;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 42px;
}

.card-event__calendar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #F1F6F5;
    padding: 0.2rem 1rem;
    margin-left: -1rem;
    margin-right: -1rem;
    font-weight: 400;
    font-size: 14px;
}

.card-event__footer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.card-event__action {
    height: 32px;
    text-align: center;
    padding: 0.3rem 1rem;
    font-weight: 400;
    transition: all 0.5s;
    border-radius: 0.2rem;
    color: #fff;
    background: var(--red);
    border-radius: 50px;
    white-space: nowrap;
    font-size: 14px;
}

.eventCard:hover {
    cursor: pointer;
}

.pricing {
    font-size: 14px;
}

.jn-detail-head {
    position: absolute;
    top: -1.5rem;
    left: -1.5rem;
    right: 0rem;
    overflow: hidden;
}

.jn-detail-head img {
    height: 70vh;
    filter: blur(18px);
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem
}

.main-page {
    min-height: 70vh;
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
}

.btnTicketStyle {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    line-height: 1.2;
    text-decoration: none;
}

.btnDark {
    color: #fff;
    background-color: var(--black);
    border-color: var(--black);
}

.btnDanger {
    color: #fff;
    background-color: var(--red);
    border-color: var(--red);
}

.btnAdmin {
    color: var(--red);
    background-color: var(--white);
    border-color: var(--red);
    border-style: solid;
}

.btn-sm,
.btn-group-sm>.btn {
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
}

.btnDanger:focus,
.btnDanger.focus {
    color: #fff;
    background-color: var(--red);
    border-color: #ff0101;
    box-shadow: 0 0 0 2px rgba(255, 7, 7, 0.5);
}

.btnDanger:hover {
    color: #fff;
    background-color: var(--red);
    border-color: var(--red);
}

.btnDark:focus,
.btnDark.focus {
    color: #fff;
    background-color: var(--black);
    border-color: #222222;
    box-shadow: 0 0 0 2px rgba(50, 50, 50, 0.5);
}

.btnDark:hover {
    color: #fff;
    background-color: var(--black);
    border-color: var(--black);
}

.c-pointer {
    cursor: pointer;
}

.custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

small,
.small {
    font-size: 80%;
    font-weight: 400;
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 1;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.tbtn-fixed__title {
    display: none;
}

.tbtn-fixed.active .tbtn-fixed__title {
    display: flex
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.btn-block {
    display: block;
    width: 100%;
}


.tbtn-fixed.active {
    background: #fff;
    margin-bottom: 0 !important;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.5rem 1rem;
    z-index: 999;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.maps-preview {
    display: block;
    position: relative;
}

.maps-preview:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 8;
    background: rgba(0, 0, 0, 0.5);
}

.maps-preview .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9;
    transform: translate(-50%, -50%)
}

.orgnizer-url {
    color: var(--red) !important;
    text-decoration: none !important;
    background-color: transparent !important;
}

.event_image {
    width: 13%;
    float: left;
    margin-right: 17px;
    border-radius: 6px;
}

.ju-nd-title {
    font-weight: bold;
    font-size: 16px;
    color: #000;
}

.ju-gray {
    color: #585759;
}

.ju-block {
    display: block;
}

.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.time {
    font-family: arial;
    text-align: center;
    position: relative;
    bottom: 4px;
}

.time>.minute {
    font-size: 30px;
    font-weight: bold;
    color: black;
}

.time>.minute::after {
    content: ":";
    font-size: 30px;
    font-weight: bold;
    color: black;
}

.time>.second {
    font-size: 30px;
    font-weight: bold;
    color: black;
}

.time>.remainingmessage {
    font-size: 12px;
    color: #A8A8A8;
    width: 172px;
    margin: 0 auto;
}

.progress-time {
    text-align: center;
    position: absolute;
    color: white !important;
    font-weight: bold;
    width: 93%;
    padding: 5px;
    bottom: 0;
    top: -2px;
}


.footer-page:before {
    content: "";
    /* position: absolute; */
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.footer-page {
    background: linear-gradient(to left, var(--red), var(--black));
    background-size: cover;
    background-position: center 40%;
    color: #fff;
    margin-top: 2rem;
    padding: 3rem 0 2rem;
    /* position: relative; */
}

.footer-nav {
    margin-bottom: 1.5rem;
}

.footer-nav>a {
    font-weight: 300;
    font-size: 1rem;
    display: block;
    color: #fff;
    padding: 0.5rem 0;
    text-decoration: none;
}

.ju-nd-title {
    font-weight: bold;
    font-size: 16px;
    color: #000;
}

.jn-progress {
    height: 3px;
    background-color: #4c4747;
}

.jn-progress-bar {
    background-color: var(--red) !important;
}

.jn-progress-bar:after {
    content: '';
    display: inline-block;
    width: 6.5px;
    height: 2.5px;
    position: relative;
    bottom: 2px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: var(--red);
}

.jn-detail-payment {
    margin-top: 30px;
    position: relative !important;
    box-shadow: 1px 5px 16px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    border: 1px solid #ddd;
    top: inherit;
}

.fs14 {
    font-size: 14px;
}

.jn-transparent-border {
    border-color: rgba(0, 0, 0, 0);
    border: 1px solid transparent;
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    border-top: none;
    background: #FAFAFA;
    border-bottom: 2px solid white;
}

.table-totals>tbody>tr>td,
.table-totals>tbody>tr>th,
.table-totals>tfoot>tr>td,
.table-totals>tfoot>tr>th,
.table-totals>thead>tr>td,
.table-totals>thead>tr>th {
    border-top: none;
    background: white;
    border-bottom: 1px solid white;
}

.jn-payment-table {
    table-layout: fixed;
    width: 100%;
    word-break: break-word;
    caption-side: top;
}

.jn-payment-table>tbody>tr>td {
    vertical-align: middle;
    text-align: right;
}

.panel {
    background-color: #FAFAFA;
}

.panel-group .panel {
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    border: 1px solid #ddd;
}

.form-group {
    margin-bottom: 15px;
}

.ju-waves {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
}

.pull-right {
    float: right !important;
}

.submitData.pull-right {
    padding-bottom: 20px;
}

.jn-buy-button {
    background-color: var(--red) !important;
    color: #fff !important;
    /* min-width: 230px; */
    border-radius: 6px !important;
    font-family: arial;
    padding: 6px 25px;
}

.detail-fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    left: 0;
    right: 0;
    margin: 0;
    height: 60px;
    border-top: 1px solid #bababa;
    z-index: 999;
}


.detail-fixed {
    background: #eee;
    border-top: none;
    height: 80px;
}

.detail-fixed-totals {
    position: absolute;
    text-align: center;
    background: white;
    border-radius: 50px;
    height: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
    right: 10px;
    left: 10px;
}

.detail-fixed.close {
    bottom: -60px;
}

.jn-collapse-icon {
    float: right;
    font-size: 23px;
    color: #B2B2B2;
    position: relative;
    right: 2%;
}

.jn-collapse-icon {
    font-size: 30px;
}

.jn-collapse-icon.fa-angle-down {
    text-align: center;
    float: initial;
}

.customFontSize {
    font-size: '20px';
}

@media only screen and (max-width: 767px) {
    .customFontSize {
        font-size: '14px';
    }
}

@media only screen and (max-width: 767px) {
    .jn-progress.fixed {
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        z-index: 99991;
    }
}

@media only screen and (max-width: 767px) {
    .jn-back-container {
        margin-top: -6px;
        border-right: none;
    }
}

@media (max-width: 767.98px) {
    .row-inline-mobile {
        flex-wrap: nowrap !important;
        overflow-y: hidden
    }
}

@media (min-width: 576px) {
    .tbtn-fixed.active .tbtn-fixed__btn {
        width: auto;
        flex: 0 0 auto;
        margin-right: 3.5rem
    }
}

@media only screen and (min-width : 1120px) {
    .event_image {
        width: 13%;
    }
}

@media only screen and (max-width: 767px) {
    .ju-nd-title {
        font-size: 14px;
    }
}

@media only screen and (max-width: 767px) {
    .jn-progress {
        height: 30px;
    }

    .jn-progress-bar:after {
        height: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .time>.minute {
        font-size: 15px;
        color: white;
    }

    .time>.minute:after {
        content: ":";
        font-size: 15px;
        color: white;
    }

    .time>.second {
        font-size: 15px;
        color: white;
    }
}

@media only screen and (max-width: 767px) {
    .jn-detail-payment {
        position: fixed !important;
        bottom: 0;
        left: 0;
        right: 0;
        background: #FFF;
        margin: 0;
        padding: 0;
        border: 1px solid #B2B2B2;
        border-top: none;
        overflow: auto;
        z-index: 9999;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        top: 992px;
    }
}

@media only screen and (max-width: 767px) {
    .jn-buy-button {
        width: 100% !important;
        margin: 20px 0 !important;
    }

    .btn-register-participants {
        width: 100%;
        margin-bottom: 40px;
    }
}