.a-custom {
  font-size: 1.25vw;
  text-decoration: none;
  color: white !important;
  margin-top: -6px;
}

.a-custom:hover {
  font-weight: 700;
}

.link-custom:hover {
  font-weight: 700;
}

.button-custom {
  background-color: white;
  border-color: #ff0043;
  color: #ff0043;
  font-size: 1.25vw;
  border-radius: 100px;
}

.button-custom:hover {
  background-color: #ff0043;
  border-color: #ff0043;
  color: white;
}
.btn-pe:hover {
  opacity: 0.7;
  cursor: pointer;
}

.hover-pointer:hover {
  cursor: pointer;
}

.pl-var {
  padding-left: var;
}

.button-red {
  background-color: #ff0043;
  color: white;
}

.button-red:hover {
  opacity: 0.7;
  cursor: pointer;
}
.admin-btn {
  width: 27.92vw;
  height: 3.91vw;
  background-color: #9b9b9b;
  color: white;
  border-radius: 20px;
  text-align: center;
}

.admin-btn:hover {
  background-color: #ff0043;
  cursor: pointer;
}

/*#region GLOBAL*/

/*#region Boton*/
.admin-title:hover {
  color: #ff0043;
}

/*#endregion Boton*/

/*#region ICON*/
.iconmenu-pe {
  display: inline;
  height: 1.2vw;
}
/*#endregion ICON*/

/*#endregion GLOBAL*/

/*#region HOME*/

/*#region CAROUSEL*/
.carousel-pe {
  /* height: 23.18vw; */
  justify-content: center;
}
.imagecarousel-pe {
  width: 100%;
  height: 23.38vw;
}

@media (max-width: 576px) {
  .carousel-pe {
    height: 60.27vw;
  }

  .imagecarousel-pe {
    width: 100%;
    height: 100%;
  }
}
/*#endregion*/

/*#region CINE*/
.cine-pe {
  background-color: #ff0043;
  margin-top: 0px;
}

.subcine-pe {
  height: 3.91vw;
  justify-content: center;
}
.slick-dots {
  display: none !important;
}
@media (max-width: 576px) {
  .subcine-pe {
    height: 10.83vw;
  }
}
/*#endregion*/

/*#region SEARCH*/
.search-pe {
  height: 4.27vw;
  margin-top: 1.3vw;
}

.searchdiv-pe {
  color: #7f7f7f;
  font-size: 2.19vw;
  height: 3.8vw;
  width: 49.1vw;
  border-radius: 20px;
  margin-left: 1.61vw;
  background-color: #ededed;
}

.imgsearch-pe {
  height: 1.93vw;
  width: 1.93vw;
}

.iconsearch-pe {
  height: 2.6vw;
}

.fontsearch-pe {
  font-size: 1.56vw;
}

@media (max-width: 576px) {
  .search-pe {
    height: 27.78vw;
    margin-top: 4.32vw;
  }
  .searchdiv-pe {
    justify-self: center;
    color: #7f7f7f;
    font-size: 4.17vw;
    height: 10vw;
    width: 91.67vw;
    border-radius: 20px;
    background-color: #ededed;
    margin-left: 4.17vw;
  }

  .iconsearch-pe {
    height: 8.33vw;
    margin-top: 2.5vw;
  }
  .fontsearch-pe {
    font-size: 3.89vw;
  }
}
/*#endregion*/

/*#region CHEVERE*/
.chevere-pe {
  height: auto;
  margin-top: 1.04vw;
  font-size: 4.375vw;
}
.imgchevere-pe {
  height: 3.54;
  margin-left: 1.61vw;
}

@media (max-width: 576px) {
  .chevere-pe {
    margin-top: 0vw;
    font-size: 6.67vw;
  }
  .imgchevere-pe {
    height: 5.56vw;
    margin-left: 4.17vw;
  }
}
/*#endregion*/

/*#region CARD*/
.card-custom:hover {
  cursor: pointer;
  opacity: 0.8;
}
/*#endregion*/

/*#endregion*/

/*#region EVENT*/

/*#region UPPER COMPONENT*/
.blur-bg {
  height: 28.65vw;
  background-size: cover;
  background-position: -1vw -1vw;
  filter: blur(36px);
  position: absolute;
  z-index: -1;
}

.dark-opacity {
  height: 28.65vw;
  background-color: rgba(0, 0, 0, 0.5);
}

.small-padding {
  height: 28.65vw;
  padding-top: 1.46vw;
}

.image-div {
  align-content: center;
  display: grid !important;
}

.image-event {
  height: 25.73vw;
  width: 49.06vw;
  border-radius: 20px;
}

.tickets-pe {
  min-height: 19.9vw;
  width: 24.58vw;
  border-radius: 15px;
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.5);
  background-color: white;
  padding-top: 1.35vw;
  padding-bottom: 1.35vw;
  position: absolute;
}

.title {
  font-size: 1.46vw;
  padding-left: 1.67vw;
  padding-right: 1.67vw;
  margin-top: -0.3vw;
}

.date {
  margin-top: -0.5vw;
  font-size: 1.25vw;
  padding-left: 1.67vw;
  padding-right: 1.67vw;
}

.time {
  overflow: hidden;
  text-overflow: inherit;
  white-space: nowrap;
}

.icon-height {
  height: 1.15vw;
}

.icon-padding {
  margin-top: 0.2vw;
}

.tickets-text {
  font-size: 1.46vw;
  margin-top: -0.5vw;
  padding-left: 1.67vw;
  padding-right: 1.67vw;
  margin-bottom: 0vw;
}

.grid-pe {
  padding-left: 1.67vw;
  padding-right: 1.67vw;
  padding-top: 0.47vw;
  padding-bottom: 0.47vw;
  background-color: #f1f1f1;
  height: 2.86vw;
  margin-bottom: 0.67vw;
}

.grid-type {
  font-size: 1.04vw;
  margin-top: -0.3vw;
}

.grid-total {
  padding-left: 1.67vw;
  padding-right: 1.67vw;
  background-color: #f1f1f1;
  height: 1.46vw;
  font-size: 1.04vw;
  padding-top: 0.07vw;
  margin-bottom: 0.5vw;
}

@media (max-width: 576px) {
  .grid-total {
    padding-left: 6.11vw;
    padding-right: 6.11vw;
    background-color: #f1f1f1;
    height: 6.11vw;
    font-size: 3.89vw;
    padding-top: 0.07vw;
    margin-bottom: 0.5vw;
  }
}

.grid-margin {
  margin-top: -0.4vw;
}

.grid-font-price {
  font-size: 1.25vw;
}

.grid-small-margin {
  margin-top: 0vw;
}

.grid-qty {
  font-size: 1.56vw;
  margin-top: -0.4vw;
  min-width: 2.66vw;
}

.grid-qty-height {
  height: 1.25vw;
}

.grid-padding-ad {
  padding-left: 1.67vw;
  padding-right: 1.67vw;
}

.grid-ad-text {
  font-size: 0.83vw;
  text-align: justify;
  line-height: 0.8vw;
  padding-left: 0.57vw;
}

.grid-ad-text-2 {
  margin-top: 0.3vw;
  font-size: 0.83vw;
  padding-left: 0.57vw;
  color: #ff0043;
}

.grid-div-btn {
  padding-left: 1.67vw;
  margin-top: 0.8vw;
}

.grid-div-btn2 {
  height: 2.24vw;
  width: 21.2vw;
  border-radius: 21.5px;
  font-size: 1.56vw;
  text-decoration: none;
}

@media (max-width: 576px) {
  .blur-bg {
    height: 60.83vw;
    background-size: cover;
    background-position: -1vw -1vw;
    filter: blur(10px);
    position: absolute;
    z-index: -1;
  }

  .dark-opacity {
    height: auto;
    background-color: rgba(0, 0, 0, 0.5);
    padding-top: 6.39vw;
  }

  .small-padding {
    height: 31.45vw;
    padding-top: 2.81vw;
  }

  .image-div {
    align-content: flex-start;
    display: flex !important;
  }

  .image-event {
    height: 48.33vw;
    width: 92.22vw;
    border-radius: 10px;
  }

  .ticket-small {
    padding-top: 1.67vw;
    margin-top: 6.39vw;
    padding-left: 4.72vw;
    background-color: white;
  }
  .tickets-pe {
    width: 90.28vw;
    border-radius: 15px;
    box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.5);
    background-color: white;
    padding-top: 5vw;
    padding-bottom: 5vw;
    position: relative;
  }

  .title {
    font-size: 5.56vw;
    padding-left: 3.33vw;
    padding-right: 3.33vw;
    margin-top: -0.3vw;
  }

  .date {
    font-size: 3.89vw;
    padding-left: 3.33vw;
    padding-right: 3.33vw;
  }

  .time {
    overflow: hidden;
    text-overflow: inherit;
    white-space: nowrap;
  }

  .icon-height {
    height: 3.33vw;
  }

  .icon-padding {
    margin-top: 0.2vw;
  }

  .tickets-text {
    font-size: 5.56vw;
    padding-left: 3.33vw;
    padding-right: 3.33vw;
    margin-bottom: 0.4vw;
  }

  .grid-pe {
    padding-left: 3.33vw;
    padding-right: 3.33vw;
    padding-top: 1.3vw;
    padding-bottom: 1.3vw;
    background-color: #f1f1f1;
    height: 9.44vw;
    margin-bottom: 1.94vw;
  }

  .grid-type {
    font-size: 3.33vw;
    margin-top: -0.3vw;
  }

  .grid-margin {
    margin-top: -1.8vw;
  }

  .grid-font-price {
    font-size: 4.17vw;
    margin-top: 0vw;
  }

  .grid-qty {
    font-size: 5.56vw;
    margin-top: -1.5vw;
    min-width: 7.92vw;
  }

  .grid-qty-height {
    height: 3.75vw;
  }

  .grid-padding-ad {
    padding-left: 6.11vw;
    padding-right: 9.72vw;
    margin-top: 3vw;
  }

  .grid-ad-text {
    font-size: 2.78vw;
    text-align: justify;
    line-height: 2.5vw;
    padding-left: 2.5vw;
  }

  .grid-ad-text-2 {
    margin-top: 1vw;
    font-size: 2.78vw;
    padding-left: 2.5vw;
    color: #ff0043;
  }

  .grid-div-btn {
    padding-left: 6.11vw;
    margin-top: 2.36vw;
  }

  .grid-div-btn2 {
    height: 9.44vw;
    width: 74.44vw;
    border-radius: 21.5px;
    font-size: 6.67vw;
    text-decoration: none;
  }
}
/*#endregion*/

/*#region LOWER COMPONENT*/
.low-div {
  min-height: 23.28vw;
  background-color: white;
  padding-top: 1.46vw;
}

.low-div-info {
  padding-left: 1.72vw;
  padding-right: 1.72vw;
  padding-bottom: 1.72vw;
  padding-top: 2.08vw;
  border-radius: 15px;
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.5);
  margin-bottom: 4.27vw;
}

.low-title-size {
  font-size: 1.67vw;
}

.low-summary-size {
  font-size: 1.25vw;
}

.low-info-size {
  font-size: 1.67vw;
  margin-top: 1.5vw;
}

.low-map-main {
  height: 20.47vw;
  width: 24.32vw;
  border-radius: 15px;
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.low-map-city {
  height: 3.8vw;
  padding-left: 1.88vw;
  padding-top: 0.625vw;
}

.low-city-font {
  font-size: 1.46vw;
}

.low-map-title {
  font-size: 1.2vw;
  margin-top: -0.7vw;
}

.low-map-image {
  height: 12.76vw;
  overflow: hidden;
  position: relative;
}

.low-map-blur {
  position: relative;
  z-index: 0;
  height: 100%;
  width: 100%;
}

.low-map-blur:hover {
  cursor: pointer;
  opacity: 0.8;
}

.low-map-blur2 {
  height: 14.3vw;
  margin-top: -14.3vw;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
  position: relative;
  z-index: 1;
}

.low-organizer {
  height: 4.03vw;
}

.low-organizer2 {
  width: 6.82vw;
}

.low-organizer-image {
  height: 2.38vw;
}

.low-organizer-text {
  font-size: 1.46vw;
  margin-top: 0.3vw;
  margin-bottom: -0.4vw;
}

.low-organizer-name {
  font-size: 1.04vw;
  color: #ff0043;
}

@media (max-width: 576px) {
  .low-div {
    min-height: 29.59vw;
    background-color: white;
    padding-top: 3.06vw;
  }

  .low-div-info {
    padding-left: 3.06vw;
    padding-right: 3.06vw;
    padding-bottom: 4.44vw;
    padding-top: 4.44vw;
    border-radius: 15px;
    box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.5);
    margin-bottom: 0vw;
  }

  .low-title-size {
    font-size: 5.56vw;
  }

  .low-summary-size {
    font-size: 3.89vw;
  }

  .low-info-size {
    font-size: 5.56vw;
    margin-top: 1.5vw;
  }

  .low-map-main {
    height: 83vw;
    width: 91.66667%;
    border-radius: 15px;
    margin-left: 4.17vw;
    box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.5);
    margin-bottom: 6.39vw;
    margin-top: 3.06vw !important;
  }

  .low-map-city {
    padding-left: 6.94vw;
    padding-top: 2.78vw;
    height: 14.95vw;
  }

  .low-city-font {
    font-size: 5.56vw;
  }

  .low-map-title {
    font-size: 4.44vw;
    margin-top: -2.1vw;
  }

  .low-map-image {
    height: 53.09vw;
    overflow: hidden;
    position: relative;
  }

  .low-map-blur {
    position: relative;
    z-index: 0;
  }

  .low-map-blur2 {
    height: 53.09vw;
    margin-top: -53.09vw;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
    position: relative;
    z-index: 1;
  }

  .low-organizer {
    height: 14.95vw;
    padding-left: 7.09vw;
  }

  .low-organizer2 {
    width: 11.31vw;
  }

  .low-organizer-image {
    height: 8.81vw;
  }

  .low-organizer-text {
    font-size: 5.56vw;
    margin-top: 1.1vw;
    margin-bottom: -1.7vw;
  }

  .low-organizer-name {
    font-size: 4.17vw;
    color: #ff0043;
  }
}
/*#endregion*/

/*#endregion*/

/*#region CHECKOUT*/

/*#region STEPS-CONTENT*/
.pe {
  margin-bottom: 3vw;
}

.a-pe {
  height: 30.99vw;
  width: 37.55vw;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.5);
}
.b-pe {
  font-size: 1.86vw;
}
.c-pe {
  height: 3vw;
  font-size: 1.6vw;
}
.d-pe {
  margin-top: 1.5vw;
}
.e-pe {
  margin-top: -5vw;
}

.f-pe {
  height: 1.98vw;
  width: 7.45vw;
}
.g-pe {
  margin-left: 4.9vw;
  height: auto;
  width: 34.69vw;
  border-radius: 25px;
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.45);
}

.h-pe {
  margin-left: 1.72vw;
  font-size: 1.85vw;
}
.i-pe {
  font-size: 1.25vw;
  margin-left: 1.72vw;
}
.j-pe {
  height: 1.15vw;
}

.k-pe {
  margin-top: 0.3vw;
}

.l-pe {
  margin-top: 1.35vw;
}
.m-pe {
  padding-left: 1.72vw;
  font-size: 1.85vw;
}
.n-pe {
  padding-right: 1.72vw;
  font-size: 1.85vw;
}
.o-pe {
  margin-top: 1.35vw;
  font-size: 1.85vw;
  padding-left: 1.72vw;
  padding-right: 1.72vw;
}

.p-pe {
  margin-top: 1.99vw;
  margin-bottom: 1.35vw;
}

.q-pe {
  height: 2.4vw;
  margin-left: 1.77vw;
}

.r-pe {
  font-size: 1.46vw;
  margin-left: 2.55vw;
}

.s-pe {
  height: 4.84vw;
  margin-left: 1.35vw;
}
.t-pe {
  height: 5vw;
  margin-left: 2.4vw;
}
.u-pe {
  height: 0.26vw;
  width: 33.96vw;
  background-color: #ff0043;
  margin-left: 1.77vw;
}
.v-pe {
  margin-top: 1.3vw;
  height: 2.24vw;
  width: 26.15vw;
  background-color: #7f7f7f;
  color: white;
  font-size: 1.3vw;
  border-radius: 11px;
}
.w-pe {
  margin-top: 0.25vw;
}
.x-pe {
  margin-top: 0.73vw;
}
.y-pe {
  height: 5.52vw;
  width: 26.15vw;
  font-size: 0.89vw;
}
.z-pe {
  background-color: #ff0043;
}

.aa-pe {
  margin-top: 7.99vw;
  margin-bottom: 1.35vw;
}

.ab-pe {
  font-size: 1.6vw;
}

@media (max-width: 576px) {
  .pe {
    margin-top: 2vw;
  }
  .a-pe {
    height: auto;
    width: 100%;
    margin-left: 4.165%;
  }
  .b-pe {
    margin-top: 4vw;
    font-size: 5vw;
  }
  .c-pe {
    height: 7vw;
    font-size: 4.5vw;
    text-align: center;
  }
  .d-pe {
    margin-top: 4vw;
  }
  .e-pe {
    margin-top: 2vw;
  }
  .f-pe {
    height: 7vw;
    width: 50%;
    font-size: 5vw;
    margin-bottom: 4vw;
  }
  .g-pe {
    margin-top: 8vw;
    width: 100%;
    margin-left: 4.165%;
    height: auto;
  }
  .h-pe {
    padding-top: 3vw;
    margin-left: 8.33%;
    font-size: 5vw;
  }
  .i-pe {
    margin-left: 8.33%;
    font-size: 4vw;
    margin-bottom: 2vw;
  }
  .j-pe {
    height: 4vw;
  }
  .k-pe {
    margin-top: 1vw;
  }
  .l-pe {
    margin-top: 3vw;
  }
  .m-pe {
    padding-left: 8.33%;
    font-size: 5vw;
  }
  .n-pe {
    padding-right: 8.33%;
    font-size: 5vw;
  }
  .o-pe {
    margin-top: 3vw;
    font-size: 5vw;
    padding-left: 8.33%;
    padding-right: 8.33%;
    padding-bottom: 3vw;
    margin-bottom: 6vw;
  }
  .p-pe {
    margin-top: 5vw;
    margin-bottom: 2vw;
    padding-left: 8.33%;
  }

  .q-pe {
    height: 5vw;
  }

  .r-pe {
    font-size: 4vw;
    margin-top: 1vw;
    margin-left: 1vw;
  }

  .s-pe {
    height: 8vw;
    margin-left: 6vw;
  }
  .t-pe {
    height: 8vw;
    margin-left: 2.4vw;
  }

  .u-pe {
    height: 0.8vw;
    width: 83.33%;
    background-color: #ff0043;
    margin-left: 8.33vw;
  }
  .v-pe {
    margin-top: 3vw;
    height: 8vw;
    width: 83.33%;
    background-color: #7f7f7f;
    color: white;
    font-size: 3.6vw;
    border-radius: 11px;
  }
  .w-pe {
    margin-top: 0.8vw;
  }
  .x-pe {
    margin-top: 2.73vw;
  }
  .y-pe {
    height: auto;
    width: 80.33%;
    font-size: 3.3vw;
    margin-top: 2vw;
  }
  .z-pe {
    background-color: #ff0043;
  }

  .ab-pe {
    font-size: 5vw;
  }
}
/*#endregion*/

/*#endregion*/

/* #region POLICIES */
/* #region terminosycondiciones */
.po-a {
  margin-top: 4.32vw;
  margin-bottom: 4.32vw;
  background-color: white;
  text-align: justify;
  padding: 2vw;
  font-size: 1.56vw;
}
@media (width < 576px) {
  .po-a {
    margin: 0;
    padding: 8.33vw;
    font-size: 3.89vw;
  }
}
/* #endregion */
/* #endregion */
